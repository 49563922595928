import React, { useRef, useCallback } from 'react'
import styled from 'styled-components'
import { useGesture } from 'react-use-gesture'
import { useSpring, useSprings, a, animated, config } from 'react-spring'
import { isMobile } from 'react-device-detect'

import { clamp } from '../utils'
import useWindowSize, { WindowSize } from '../hooks/use-window-size'
import useResizeObserver from '../hooks/use-resize-observer'

const styles = {
  container: {
    willChange: 'transform',
    height: '100%',
  },
  item: {
    willChange: 'transform',
  },
}

type Props = {
  items: JSX.Element[]
}

const Slider: React.FC<Props> = ({ items }) => {
  const containerRef = useRef<HTMLDivElement>(null)
  const progress = useRef<number>(0)
  const containerWidth = useRef<number>(0)
  const windowWidth = useRef<number>(0)

  const [containerSpring, setContainerSpring] = useSpring(() => ({
    x: 0,
    transform: 'scale(1)',
    config: config.slow,
  }))

  const [springs] = useSprings(
    items.length,
    (_i) => ({
      //   x: i * Math.min(width, (windowWidth.current * 3) / 4),
      immediate: true,
    }),
    []
  )

  const resetContainerSping = useCallback(() => {
    if (containerWidth.current <= windowWidth.current) {
      setContainerSpring({ x: 0 })
    }
  }, [setContainerSpring])

  const onWindowResize = useCallback(
    (size: WindowSize) => {
      windowWidth.current = size.width
      resetContainerSping()
    },
    [resetContainerSping]
  )

  const onContainerResize = useCallback(
    (width: number) => {
      containerWidth.current = width
      resetContainerSping()
    },
    [setContainerSpring, resetContainerSping]
  )

  useWindowSize(onWindowResize)
  useResizeObserver(containerRef, onContainerResize)

  const handleContainerSpring = useCallback(
    (progress, delta) => {
      const x =
        containerWidth.current > windowWidth.current
          ? clamp(
              progress + delta,
              -containerWidth.current + windowWidth.current,
              0
            )
          : 0
      setContainerSpring({ x })
      return x
    },
    [setContainerSpring]
  )

  //   const runSprings = useCallback(
  //     (y) => {
  //       set((i) => ({
  //         x: -y + width * i,
  //         // config: {
  //         //   tension: (1 + items.length - (i + 1)) * 700,
  //         //   friction: 30 + (i + 1) * 40,
  //         // },
  //       }))
  //     },
  //     [width, set, items.length]
  //   )

  const bind = useGesture(
    {
      onDragStart: () => setContainerSpring({ transform: 'scale(0.97)' }),
      onDragEnd: () => setContainerSpring({ transform: 'scale(1)' }),
      onDrag: (props) => {
        const {
          delta: [x],
        } = props
        if (!x) {
          return
        }
        progress.current = handleContainerSpring(progress.current, x * 3)
        // runSprings(progress.current)
      },

      onWheel: ({ delta: [, y] }) => {
        if (!y) {
          return
        }
        progress.current = handleContainerSpring(progress.current, -y)
      },
    },
    {
      wheel: {
        enabled: !isMobile,
        axis: 'y',
      },
      drag: {
        enabled: isMobile,
        filterTaps: true,
        axis: 'x',
      },
    }
  )

  return (
    <Container
      ref={containerRef}
      {...bind()}
      // @ts-expect-error: react-spring: Type instantiation is excessively deep and possibly infinite
      style={{ ...styles.container, ...containerSpring }}
    >
      {springs.map((_, i) => (
        // @ts-expect-error: react-spring: Type instantiation is excessively deep and possibly infinite
        <a.div key={i} style={{ ...styles.item, touchAction: 'pan-y' }}>
          {items[i]}
        </a.div>
      ))}
    </Container>
  )
}

const Container = styled(animated.div)`
  height: 100%;
  margin: 0;
  flex-wrap: nowrap;
  display: inline-flex;
  list-style: none;
  will-change: transform;
`

export default Slider
