import { useEffect } from 'react'

const className = 'js-prevent-scroll-bounce'

export default function usePreventScrollBounce(): void {
  useEffect(() => {
    document.documentElement.classList.add(className)

    return () => {
      document.documentElement.classList.remove(className)
    }
  }, [])
}
