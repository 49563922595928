import React, { useLayoutEffect } from 'react'
import ResizeObserver from 'resize-observer-polyfill'

import { isClient } from '../utils'

export default function useResizeObserver(
  node: React.RefObject<HTMLElement>,
  callback: (width: number) => void
): void {
  let cancelled = false

  useLayoutEffect(() => {
    if (!isClient || !(node && node.current)) {
      return
    }

    const resizeObserver = new ResizeObserver((entries) => {
      if (cancelled) {
        return
      }
      const target = entries[0].target as HTMLElement
      callback(target.offsetWidth)
    })

    resizeObserver.observe(node.current)

    return () => {
      cancelled = true
      if (node && node.current) {
        resizeObserver.unobserve(node.current)
      }
    }
  }, [node, callback])
}
