import React, { useRef, useCallback, useState } from 'react'
import styled from 'styled-components'
import {
  useSpring,
  useChain,
  animated,
  config,
  SpringHandle,
} from 'react-spring'
import { MOBILE_MEDIA_QUERY } from 'typography-breakpoint-constants'
import { isMobileOnly } from 'react-device-detect'

import SafeHTML from './safe-html'
import Copyright from './copyright'
import ExternalLink from './external-link'

import { Author } from '../@types/core'
import IconArrow from '../assets/svg/icon-arrow.svg'

type Props = {
  content?: string
  contact?: {
    label?: string
    email?: string
  }
  authors?: Author[]
}

const About: React.FC<Props> = ({ content, contact, authors = [] }) => {
  const contentWrapperSpringRef = useRef<SpringHandle>(null)
  const contentWrapperProps = useSpring({
    from: { opacity: 0, y: -20 },
    to: { opacity: 1, y: 0 },
    ref: contentWrapperSpringRef,
    config: config.slow,
  })

  const contactSpringRef = useRef<SpringHandle>(null)
  const contactProps = useSpring({
    from: { opacity: 0, y: -10 },
    to: { opacity: 1, y: 0 },
    ref: contactSpringRef,
    config: config.slow,
  })

  const footerSpringRef = useRef<SpringHandle>(null)
  const footerProps = useSpring({
    from: { opacity: 0 },
    to: { opacity: 1 },
    ref: footerSpringRef,
    config: config.stiff,
  })

  const styledIconArrowSpringRef = useRef<SpringHandle>(null)
  const styledIconArrowProps = useSpring({
    from: { opacity: 0, [isMobileOnly ? 'y' : 'x']: -10 },
    to: { opacity: 1, [isMobileOnly ? 'y' : 'x']: 0 },
    ref: styledIconArrowSpringRef,
    config: config.gentle,
  })

  useChain(
    [
      contentWrapperSpringRef,
      contactSpringRef,
      footerSpringRef,
      styledIconArrowSpringRef,
    ],
    [0.25, 0.5, 0.6, 0.8]
  )

  const [email, setEmail] = useState<string>('mailto:')
  const onClick = useCallback(
    (_event) => {
      setEmail(`mailto:${contact?.email}`)
    },
    [setEmail, contact?.email]
  )

  return (
    <Container>
      {content && (
        <ContentWrapper>
          <Content style={contentWrapperProps}>
            <StyledSafeHTML type="h1" content={content} />
          </Content>

          {contact?.label && contact.email && (
            <Contact style={contactProps}>
              <StyledExternalLink href={email} onClick={onClick}>
                / {contact?.label}
              </StyledExternalLink>
            </Contact>
          )}
        </ContentWrapper>
      )}

      <Footer style={footerProps}>
        <Copyright authors={authors} />
      </Footer>

      <IconArrowWrapper style={styledIconArrowProps}>
        <StyledIconArrow />
      </IconArrowWrapper>
    </Container>
  )
}

const Container = animated(styled.div`
  display: grid;
  margin-right: 25px;
  width: 480px;
  max-width: 100vw;
  height: 100%;
  padding: 2rem;
  grid-template-columns: 1fr minmax(min-content, 8fr) min-content 1fr;
  grid-auto-rows: auto min-content;
  grid-column-gap: 2.3rem;
  grid-row-gap: 2.3rem;
  white-space: normal;

  background-color: var(--black-color);

  ${MOBILE_MEDIA_QUERY} {
    width: 80vw;
    max-height: calc(var(--vh, 1vh) * 100);
    grid-template-columns: auto;
    column-gap: 0.3rem;
    row-gap: 0.3rem;
  }
`)

const ContentWrapper = animated(styled.div`
  grid-column: 2 / 3;
  grid-row: 1;
  align-self: center;

  > * :last-child:not(:only-child) {
    margin-top: 3rem;
  }

  ${MOBILE_MEDIA_QUERY} {
    grid-column: 1;

    > * :last-child:not(:only-child) {
      margin-top: 1.73rem;
    }
  }
`)

const Content = animated(styled.div`
  will-change: transform, opacity;
`)

const StyledSafeHTML = styled(SafeHTML)`
  font-weight: 400;
  font-size: 1.27rem;
  line-height: 1.85rem;
  letter-spacing: 1px;
  color: var(--light-grey-color);
  margin: 0;

  strong {
    color: var(--white-color);
  }

  p:last-child {
    margin-bottom: 0;
  }

  ${MOBILE_MEDIA_QUERY} {
    font-size: 1.07rem;
    line-height: 1.65rem;
  }
`

const Contact = animated(styled.div`
  will-change: transform, opacity;
`)

const StyledExternalLink = styled(ExternalLink)`
  display: inline-block;
  color: var(--white-color);

  &::before {
    background-color: var(--white-color);
  }
`

const Footer = animated(styled.div`
  grid-column: 2;
  grid-row: 2;
  font-size: 0.66rem;
  line-height: 0.94rem;
  align-self: center;
  will-change: opacity;

  ${MOBILE_MEDIA_QUERY} {
    grid-column: 1;
  }
`)

const IconArrowWrapper = animated(styled.div`
  grid-column: 3;
  grid-row: 2;
  will-change: transform, opacity;

  ${MOBILE_MEDIA_QUERY} {
    display: none;
  }
`)

const StyledIconArrow = styled(IconArrow)`
  fill: var(--light-grey-color);
  transform: rotate(-90deg);
`

export default About
