import React from 'react'
import { OutboundLink, OutboundLinkProps } from 'gatsby-plugin-google-analytics'
import styled from 'styled-components'

type Props = {
  href: string
}

const isAbsoluteURL = new RegExp('^(?:[a-z]+:)?//', 'i')

const ExternalLink: React.FC<Props & OutboundLinkProps> = ({
  children,
  href,
  ...rest
}) => {
  if (!href || (!isAbsoluteURL.test(href) && !href.startsWith('mailto:'))) {
    throw new Error(
      `ExternalLink with href ${href} cannot be used for internal linking.`
    )
  }

  return (
    <A
      href={href}
      role="link"
      target="_blank"
      rel="noopener noreferrer"
      {...rest}
    >
      {children}
    </A>
  )
}

const A = styled(OutboundLink)`
  position: relative;
  color: inherit;
  text-decoration: inherit;
  font-weight: 700;

  @media (hover) {
    &::after {
      content: '';
      position: absolute;
      width: 0%;
      height: 1px;
      right: 0;
      bottom: -2px;
      background-image: var(--deco-color);
      transition: width 150ms cubic-bezier(0.32, 0, 0.67, 0);
    }

    &:hover {
      color: var(--primary-color);
      background: var(--deco-color);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      &::after {
        width: 100%;
        left: 0;
        right: auto;
      }
    }
  }
`

export default ExternalLink
