import React, { Fragment } from 'react'
import styled from 'styled-components'

import ExternalLink from './external-link'
import { Author } from '../@types/core'

type Props = {
  authors: Author[]
}

const Copyright: React.FC<Props> = ({ authors }) => {
  return (
    <Footer role="contentinfo">
      Handcrafted with
      <Emoticon role="img" aria-label="heart suit">
        ♥
      </Emoticon>
      {!!authors.length && (
        <div>
          by
          {authors.map(({ name, url }, index) => (
            <Fragment key={`${name}-${url}`}>
              <A href={url}>{name}</A>
              {index !== authors.length - 1 && 'and'}
            </Fragment>
          ))}
        </div>
      )}
    </Footer>
  )
}

export default Copyright

const Footer = styled.footer`
  font-size: 0.66rem;
  line-height: 0.94rem;
  color: var(--light-grey-color);
`

const Emoticon = styled.span`
  color: var(--primary-color);
  margin: 0 4px;
`

const A = styled(ExternalLink)`
  margin: 0 5px;

  &::before {
    background-color: var(--white-color);
  }
`
