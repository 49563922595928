import React from 'react'
import { graphql } from 'gatsby'
import { Helmet } from 'react-helmet'
import { CollectionPage } from 'schema-dts'
import { helmetJsonLdProp } from 'react-schemaorg'
import styled, { ThemeProvider } from 'styled-components'
import { MOBILE_MEDIA_QUERY } from 'typography-breakpoint-constants'

import { mapBaseProjectFieldsFragmentToBaseProject } from '../mapper'
import Layout from '../components/layout'
import About from '../components/about'
import Slider from '../components/slider'
import Preview from '../components/preview'
import usePreventScrollBounce from '../hooks/use-prevent-scroll-bounce'
import {
  renderMovieJsonLdProp,
  renderPersonJsonLdProp,
  computeProjectPathname,
} from '../utils'
import { BaseProject, Author, Contact } from '../@types/core'

type Props = {
  data: GatsbyTypes.IndexPageQuery
  location: Location
}
function isAuthorValid(
  content?: Pick<GatsbyTypes.WpPage_Acf_authors, 'name' | 'url'>
): content is Author {
  return !!content?.name && !!content?.url
}

function isContactValid(
  content?: Pick<GatsbyTypes.WpPage_Acf_Contact, 'email' | 'label'>
): content is Contact {
  return !!content?.label && !!content?.email
}

function renderAbout(data: GatsbyTypes.IndexPageQuery): JSX.Element {
  return <About {...mapIndexPageQueryToAboutProps(data)} />
}

function mapIndexPageQueryToAboutProps(data: GatsbyTypes.IndexPageQuery) {
  return {
    content: data.wpPage?.acf?.content,
    contact: isContactValid(data.wpPage?.acf?.contact)
      ? data.wpPage?.acf?.contact
      : undefined,
    authors: data.wpPage?.acf?.authors?.filter(isAuthorValid),
  }
}

function renderPreview(projects: BaseProject[]): JSX.Element[] {
  return projects.map((project, index) => (
    <ThemeProvider
      key={project.id}
      theme={{
        colors: { primary: project.color },
      }}
    >
      <PreviewWrapper>
        <StyledPreview project={project} index={index + 1} />
      </PreviewWrapper>
    </ThemeProvider>
  ))
}

const IndexPage: React.FC<Props> = ({ location, data }) => {
  usePreventScrollBounce()

  const projects = (data?.wpPage?.acf?.projects ?? []).reduce<BaseProject[]>(
    (acc, project, index) =>
      project
        ? [...acc, mapBaseProjectFieldsFragmentToBaseProject(project, index)]
        : acc,
    []
  )

  return (
    <Layout location={location} seo={data.wpPage?.seo}>
      <Helmet
        script={[
          helmetJsonLdProp<CollectionPage>({
            '@context': 'https://schema.org',
            '@type': 'CollectionPage',
            author: renderPersonJsonLdProp(),
            mainEntity: projects.map((project, index) => ({
              '@type': 'ListItem',
              position: index,
              item: {
                url: computeProjectPathname(project.slug),
                ...renderMovieJsonLdProp(project),
              },
            })),
          }),
        ]}
      />
      <Wrapper>
        <Slider
          items={[renderAbout(data), ...renderPreview(projects)]}
        ></Slider>
      </Wrapper>
    </Layout>
  )
}

export const IndexPageQuery = graphql`
  query IndexPage {
    wpPage(isFrontPage: { eq: true }) {
      seo {
        ...WpSEOFragment
      }
      acf {
        content
        authors {
          name
          url
        }
        contact {
          label
          email
        }
        projects {
          ...BaseProjectFields
        }
      }
    }
  }
`

const Wrapper = styled.div`
  overflow: hidden;
  height: calc(100 * var(--vh));
`

const StyledPreview = styled(Preview)``

const PreviewWrapper = styled.article`
  width: 340px;
  height: 100%;
  padding: 35px 27px;

  ${MOBILE_MEDIA_QUERY} {
    max-width: 80vw;
  }
`

export default IndexPage
